import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { CompanyParamsService } from 'src/app/core/services/company-params.service';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-hakuadventure',
  templateUrl: './hakuadventure.component.html',
  styleUrls: ['./hakuadventure.component.scss']
})
export class HakuadventureComponent implements OnInit {

  @Output() public headerHeight: EventEmitter<any> = new EventEmitter();
  @ViewChild('header') headerRef!: ElementRef;

  constructor(
    public companyParamsService: CompanyParamsService
  ) { }
  showMenu: boolean = false;

  ngOnInit(): void {
  }

  renderMenu() {
    this.showMenu = !this.showMenu;
  }

  goFavorites() {
    const urlFavorites: any =
      this.companyParamsService.getNameUrl() +
      '/wishlist';
    Utils.openNewTab(urlFavorites);
  }

  ngAfterViewInit() {
    var height = this.headerRef.nativeElement.offsetHeight;
    this.headerHeight.emit(height);
  }

}
