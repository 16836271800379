<nav #header class="fixed-top">
    <div class="containerHeader" style="background-color: #2c2c2c">
        <div class="contact">
            <span>Talk with an expert insider!</span>
            <a href="tel:+17732809571" role="button">
                <i class="fa fa-phone" style="color:#adb4b9"></i>
                <span>USA &amp; Canada:1-773-280-9571</span>
            </a>
            <a href="tel:+593987004404" role="button">
                <i class="fa fa-phone" style="color:#adb4b9"></i>
                <span>Ecuador: +593-987-004-404</span>
            </a>
            <a href="mailto:info@galapagosinsiders.com" role="button">
                <i class="fa fa-envelope-o" style="color:#adb4b9"></i>
                <span>info@galapagosinsiders.com</span>
            </a>
        </div>
        <div class="social-media">
            <a href="https://www.facebook.com/galapagosinsiders/"><i class="fa fa-facebook-square"></i></a>
            <a href="https://twitter.com/insiders_ec"><i class="fa fa-twitter"></i></a>
            <a href="https://vimeo.com/galapagosinsiders"><i class="fa fa-vimeo"></i></a>
            <a href="https://www.pinterest.com/ecuadorandgalapagos/"><i class="fa fa-pinterest"></i></a>
            <a href="https://www.instagram.com/galapagosinsiders/"><i class="fa fa-instagram"></i></a>
            <a
                href="https://www.tripadvisor.com/Attraction_Review-g294308-d16856733-Reviews-Ecuador_and_Galapagos_Insiders-Quito_Pichincha_Province.html"><i
                    class="fa fa-tripadvisor"></i></a>
        </div>
        <div class="menuButton">
            <a href="{{this.companyParamsService.getWebSite()}}" class="navbar-brand"
                style="justify-content: flex-start;">
                <img src="https://galapagosinsiders.com/storage/2023/08/logo-ecuador-and-galapagos-insiders.svg"
                    height="50" alt="logo" class="brand-logo">
            </a>
            <a (click)="renderMenu()" href="javascript:void()"><i class="fa fa-bars"></i></a>
        </div>
    </div>
    <div>

    </div>
    <div class="containerMenu navbar-collapse collapse" [ngClass]="showMenu ? 'showMenu':''">
        <div class="logo-desktop">
            <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
                data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here"
                style="justify-content: flex-start;">
                <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
            </a>
            <a *ngIf="this.companyParamsService.getWebSite()" href="{{this.companyParamsService.getWebSite()}}"
                class="navbar-brand" style="justify-content: flex-start;" target="_blank">
                <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
                <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
                <img id="logoMenu" [src]=this.companyParamsService.getLogo() height="50" alt="logo"
                    class="brand-logo logoMenu">
            </a>
        </div>
        <ul class="navbar-nav">
            <li class="nav-item menuItem">
                <a href="https://galapagosinsiders.com" target="_blank">Home</a>
            </li>
            <li class="nav-item menuItem">
                <a href="https://galapagosinsiders.com/galapagos-cruises-and-tours/" target="_blank">Destinations</a>
            </li>
            <li class="nav-item menuItem">
                <a href="https://galapagosinsiders.com/travel-blog/" target="_blank">Galapagos
                    Travel Tips</a>
            </li>
            <li class="nav-item menuItem">
                <a href="https://galapagosinsiders.com/travel-blog/#AmazonRainforestTravelGuides"
                    target="_blank">Rainforest Travel
                    Tips</a>
            </li>
            <li class="nav-item menuItem">
                <a href="https://galapagosinsiders.com/contact/" target="_blank">Contact Us &
                    Book</a>
            </li>
            <li class="nav-item menuItem">
                <a href="https://galapagosinsiders.com"><i class="fa fa-search"></i></a>
            </li>
            <li class="nav-item menuItem" *ngIf="companyParamsService.getFiltersInterest()">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </li>
        </ul>
        <div class="contact-mov">
            <span>Talk with an expert insider!</span>
            <a href="tel:+17732809571" role="button">
                <i class="fa fa-phone" style="color:#adb4b9"></i>
                <span>USA &amp; Canada:1-773-280-9571</span>
            </a>
            <a href="tel:+593987004404" role="button">
                <i class="fa fa-phone" style="color:#adb4b9"></i>
                <span>Ecuador: +593-987-004-404</span>
            </a>
            <a href="mailto:info@galapagosinsiders.com" role="button">
                <i class="fa fa-envelope-o" style="color:#adb4b9"></i>
                <span>info@galapagosinsiders.com</span>
            </a>
        </div>
    </div>
</nav>