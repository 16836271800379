<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div style="background-color: #607849;" class="contactHeader">
        <div class="container green containerHeader">
            <div>
                <a style="text-decoration: none;" href="https://fusiontravelecuador.com/tel:0967987391" target="_blank">
                    <i class="fa fa-phone" style="padding: 0 10px;"></i>
                    0967987391</a>
                <a href="mailto:info@fourworldsecuador.com" target="_blank">
                    <i class="fa fa-envelope-o" style="padding: 0 10px;"></i>
                    info@fourworldsecuador.com</a>
            </div>
            <div>

            </div>
            <div><a href="https://hakuadventure.com/en/"><img class="flag"
                        src="../../../../../../../../assets/images/en-flag.svg" alt="English" height="12"> English</a> /
                <a href="https://hakuadventure.com/es/"><img class="flag"
                        src="../../../../../../../../assets/images/es.svg" alt="Spanish"> Spanish</a></div>
        </div>
    </div>

    <div class="container green containerMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://hakuadventure.com/" class="navbar-brand"
            target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <span class="custom-logo" width="180" height="50"></span>
        </a>
        <div class="containerMenu navbar-collapse collapse" style="width: inherit;">
            <ul class="navbar-nav menuContainer" style="width: inherit;">
                <li class="nav-item menuItem"><a href="https://hakuadventure.com/" target="_blank">HOME</a></li>
                <li class="nav-item menuItem"><a href="https://hakuadventure.com/about-us/" target="_blank">ABOUT US</a>
                </li>
                <li class="nav-item menuItem"><a href="https://hakuadventure.com/galapagos-cruises/"
                        target="_blank">GALAPAGOS</a></li>
                <li class="nav-item menuItem"><a href="https://hakuadventure.com/amazon/" target="_blank">AMAZON</a>
                </li>
                <li class="nav-item menuItem"><a href="https://hakuadventure.com/exploring-the-andes/"
                        target="_blank">ANDEAN</a>
                </li>
                <li class="nav-item menuItem"><a href="https://hakuadventure.com/contact-us/" target="_blank">CONTACT
                        US</a></li>
            </ul>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>


    </div>
    <div style="background-color: #607849;" class="responsiveHeader">
        <div class="container green containerHeader"
            style="justify-content: space-between; flex-direction: row!important;">
            <a class="menuButton" (click)="renderMenu()" href="javascript:void()" style="padding-right: 1em;">
                <i class="fa fa-bars fa-2x" style="padding-left: 20px;"></i>
            </a>
            <img src="https://hakuadventure.com/wp-content/uploads/2023/11/icon-white-haku-adventure.svg" width="150" />
            <div><a href="https://hakuadventure.com/es/">ES</a>/<a href="https://hakuadventure.com/en/">EN</a></div>
        </div>
    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://hakuadventure.com/" target="_blank">HOME</a></li>
        <li class="nav-item menuItem"><a href="https://hakuadventure.com/about-us/" target="_blank">ABOUT
                US</a></li>
        <li class="nav-item menuItem"><a href="https://hakuadventure.com/galapagos-cruises/"
                target="_blank">GALAPAGOS</a></li>
        <li class="nav-item menuItem"><a href="https://hakuadventure.com/amazon/" target="_blank">AMAZON</a></li>
        <li class="nav-item menuItem"><a href="https://hakuadventure.com/exploring-the-andes/"
                target="_blank">ANDEAN</a></li>
        <li class="nav-item menuItem"><a href="https://hakuadventure.com/contact-us/" target="_blank">CONTACT US</a>
        </li>

    </ul>
    <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container"
        [ngClass]="showMenu ? 'showMenu':''" style="width: inherit; display: none;">
        <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
            <i class="fa fa-heart-o"></i>
        </a>
        <span class="tooltip-text">wishlist</span>
    </div>

</nav>