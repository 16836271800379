import { Component, Injector, OnInit, EventEmitter, Output } from '@angular/core';
import { TypeListMenu } from '../../enums/type-list-menu';
import { CompanyParamsService } from '../../services/company-params.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/utils';
import { BaseComponent } from 'src/app/shared/base/base.component';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'is-what';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  typeListMenu = TypeListMenu;
  renderMenu: boolean = false;
  company?: any;
  @Output() public headerHeight: EventEmitter<any> = new EventEmitter();
  headerHeightInput: any;

  constructor(
    public companyParamsService: CompanyParamsService,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.company = this.companyParamsService.company.headerActions.headerTemplate;
    this.renderMenu = isNullOrUndefined(this.company);
  }

  goFavorites() {
    const urlFavorites: any =
      this.companyParamsService.getNameUrl() +
      '/wishlist';
    Utils.openNewTab(urlFavorites);
  }

  getHeight(height: any) {
    this.headerHeightInput = height
  }
  ngAfterViewInit() {
    this.headerHeight.emit(this.headerHeightInput);
  }

  setCustomCSS(css: string):any {
    let q = css!= undefined ? JSON.parse(css.replace("'","").replace("'","").trim()):"";
    return q;
  }


}
